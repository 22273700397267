import React from "react"

import LoadingStyles from './LoadingScreen.module.scss'

const LoadingScreen = () => {

    return (
        <section className={LoadingStyles.section}>
            <h1 className={LoadingStyles.hidden} >Loading</h1>    
        </section>
    )
}


  
  export default LoadingScreen