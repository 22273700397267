import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { navigate } from "gatsby-plugin-intl/link";
import Form from '../components/hoc/Form/Form'
import SEO from "../components/seo"
import {getData, getErrors, dataSavePending, getAccessToken, isLoggedIn, emailPasswordSend, emailPasswordSending, dataSaveSuccess} from '../state/reducers/auth';
import saveAction from '../state/async/authSaveData';
import logoutAction from '../state/async/authLogout';
import resetPasswordEmail from '../state/async/authResetPasswordEmail';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios'

const Account = ({error, data, access, dataSavePending, saveAction, logoutAction, resetEmailAction, emailPasswordSending, emailPasswordSend, dataSaveSuccess}) => {

    const invoices = data.profile.websites[0].invoice
    const i18n = useIntl().formatMessage
    const [submission, setSubmission] = useState({email: data.username})
    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(false)

    const saveHandler = (sub) => {
        console.log('SUBMISSION: ', sub)
        setSubmission(sub)
        saveAction(access.token, sub, null, 'PUT', 'user', false)
    }

    const deleteHandler = () => {
        saveAction(access.token, {env: process.env.GATSBY_ENVIRONMENT_SET}, null, 'POST', 'delete_account')
        logoutAction()
        navigate('/')
    }

    const resetEmailHandler = async () => {
        
        if(submission &&  submission.email) {
            await resetEmailAction(submission.email)
        } else {
        }
    }

    const stripePortalHandler = () => {
        console.log('clicked')
        const stripeCustomerId = data.profile.websites[0].stripe_customer_id
        if(stripeCustomerId) {
            setLoading(true)
            axios.post('/.netlify/functions/stripePortalUrl', {
                stripe_customer_id: stripeCustomerId,
                env: process.env.GATSBY_ENVIRONMENT_SET
            })
            .then((res) => {
                window.location.href = res.data.data.url
            })
            .catch((err) => {
                console.log(err)
            })
        } else {
            console.log('DATA', data)
            setLoading(true)
            axios.post('/.netlify/functions/stripeSessionId', {
                email: data.username,
                site_id: data.profile.websites[0].site_id,
                env: process.env.GATSBY_ENVIRONMENT_SET
            })
            .then((res) => {
                stripePaymentHandler(res.data.data)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
        
            const stripePaymentHandler = (id) => {
                
                const stripe = window.Stripe(process.env.GATSBY_ENVIRONMENT_SET === 'development' ? process.env.GATSBY_STRIPE_PUB_KEY :  process.env.GATSBY_STRIPE_PUB_KEY_PROD);
        
                const async = async () => {
                    const {errors} = await stripe.redirectToCheckout({
                        // Make the id field from the Checkout Session creation API response
                        // available to this file, so you can provide it as parameter here
                        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                        sessionId: id
                    })
        
                    if(errors) {
                        setLoading(false)
                    }
                }
                async()
            }
        }
    }

    return (
    <>
        <SEO title="Account settings" />
        <section className="section-padding-big">
            <div className="container">
                <div className="row center-xs">
                <div className="col col-xs-12 col-lg-4 col-xl-3 text-left">
                    <ul className="list-inline list-unstyled list-tabs list-vertical card space-xs-up">
                        <li className={[activeTab === 0 ? 'active-tab' : null, ''].join(' ')} onClick={() => setActiveTab(0)}>Profil</li>
                        <li className={[activeTab === 1 ? 'active-tab' : null, ''].join(' ')} onClick={() => setActiveTab(1)}>Betalingsoplysninger</li>    
                        <li className={[activeTab === 2 ? 'active-tab' : null, ''].join(' ')} onClick={() => setActiveTab(2)}>Slet konto</li>
                    </ul>

                </div>
                <div className="col col-xs-12 col-lg-8 col-xl-6 text-left">                    
                    <div className="card space-xs-up">
                        {activeTab == 0 ?
                            <>
                            <h2 className="h3 space-xs-up mt-big">Profil</h2>
                            <h2 className="h5">Email</h2>
                            <Form 
                                loading={dataSavePending}
                                // success={dataSaveSuccess}
                                submitHandler={saveHandler}
                                submitText={ i18n({ id: "general.save" }) }
                                formElements={[
                                    { type: 'email', name: 'username', label: 'Email', value: submission ? submission.email : '', required: true },
                                ]}
                                cancelBtn={false}
                            />
                            <h2 className="h5 mt-big">Password</h2>
                            <p>Få tilsendt et link hvor du kan ændre dit password ved at klikke på knappen nedenfor.</p>
                            {/* <button className="btn" onClick={() => resetEmailHandler()}>
                                Ændre password med email
                            </button> */}
                            <Form 
                                loading={emailPasswordSending}
                                success={emailPasswordSend}
                                submitHandler={() => resetEmailHandler()}
                                submitText="Ændre password med email"
                                formElements={[
                                ]}
                                cancelBtn={false}
                            />
                            </>
                        : null}
                        {activeTab == 1 ?
                           
                             <>
                             <h2 className="h3 space-xs-up">Betaling</h2>
                             <p>Følg linket nedenfor for at ændre eller se dine betalingsoplysnigner. Betalinger bliver varetaget af Stripe.</p>
                             <Form 
                                submitHandler={() => stripePortalHandler()}
                                loading={loading}
                                submitText="Gå til betalingsportal"
                                formElements={[
                                ]}
                                cancelBtn={false}
                            />
                             {invoices && invoices.length > 0 ? 
                             <table className="table mt-big">
                                <thead>
                                    <tr>
                                    <th>Fakturaer</th>
                                    <th>Betalt</th>
                                    <th>Pdf</th>
                                    </tr>
                                </thead>
                                
                                <tbody className="list-checkmark text-left ">
                                    {invoices.map((inv, i) => {
                                        const options = { year: 'numeric', month: 'long', day: 'numeric' }

                                        return (
                                            <tr key={i}>
                                                <th scope="row" className="text-normal">{new Date(inv.period_start).toLocaleDateString(undefined, options)}</th>
                                                <td style={inv.paid ? {position: 'relative'} : null}>
                                                    {inv.paid ? <span className="text-normal checkmark" style={{height: '100%', width: '100%', position: 'absolute', top: 0}}></span> : null}
                                                    {!inv.paid ? <a className="text-normal minus" href={inv.payment_url} target="_blank">Betal nu</a> : null }
                                                </td>
                                                <td className="text-normal">
                                                    <a href={inv.hosted_pdf} className="text-normal" target="_blank">Hent pdf</a>
                                                </td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            : null}
                            
                         </>
                        : null }
                        
                        {activeTab == 2 ?
                            <>
                            <h2 className="h3 mt-big space-xs-up">Slet konto</h2>
                            <p>Klik nedenfor hvis du ønsker at slette din konto og din hjemmeside. Dette kan ikke fortrydes, så gør det kun hvis du er sikker.</p>
                            <Form 
                                submitHandler={() => deleteHandler()}
                                submitText="Slet konto"
                                confirmModalText="Er du sikker? Dette vil slette din konto og din side. Handlingen kan ikke fortrydes."
                                formElements={[
                                ]}
                                cancelBtn={false}
                            />
                            </>
                        : null }
                    
                        
                    </div>
    
                </div>
                </div>
            </div>
        </section>
        <section>
        {/* <ul>
                    <li>Changing password</li>
                    <li>Billing
                        <ul>
                            <li>Change credit card</li>
                            <li>Link to receipts</li>
                        </ul>
                    </li>
                    </ul> */}
        </section>
    </>
    )
    }

// export default Pricing
const mapStateToProps = state => ({
    error: getErrors(state.auth),
    data: getData(state.auth),
    access: getAccessToken(state.auth),
    isLoggedIn: isLoggedIn(state.auth),
    dataSavePending: dataSavePending(state.auth),
    dataSaveSuccess: dataSaveSuccess(state.auth),
    emailPasswordSend: emailPasswordSend(state.auth),
    emailPasswordSending: emailPasswordSending(state.auth)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    saveAction: saveAction,
    logoutAction: logoutAction,
    resetEmailAction: resetPasswordEmail,
  }, dispatch)
  
  
export default connect(mapStateToProps, mapDispatchToProps)(Account);
