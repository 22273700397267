import React, {useEffect} from "react"
import LoadingScreen from './LoadingScreen/LoadingScreen'

import { connect } from 'react-redux';
import {isLoggedIn, getData, getErrors, getAccessToken, getRefreshToken, isAccessTokenExpired, isRefreshTokenExpired, isAuthenticated} from '../../state/reducers/auth';

import { navigate } from "gatsby-plugin-intl/link";

const PrivateRoute = ({isLoggedIn, data, isAuthenticated, location, loginPending, component: Component, path, ...rest}) => {

    useEffect(() => {
        if(!isAuthenticated && !loginPending) {
          
          console.log('not authenticated')
          // navigate to login
            navigate('/login')
        }
      })


    return (
        isLoggedIn && data ? <Component {...rest} /> : <LoadingScreen/>
    )
}

const mapStateToProps = state => ({
    error: getErrors(state.auth),
    data: getData(state.auth),
    isLoggedIn: isLoggedIn(state.auth),
    access: getAccessToken(state.auth),
    refresh: getRefreshToken(state.auth),
    accessExpired: isAccessTokenExpired(state.auth),
    refreshExpired: isRefreshTokenExpired(state.auth),
    isAuthenticated: isAuthenticated(state.auth)
  })
  
  
  export default connect(mapStateToProps)(PrivateRoute);