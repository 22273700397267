import {dataSaveRequest, dataSaveSuccess, dataSaveFailure} from '../actions/auth';
import cloneDeep from 'lodash.clonedeep'

const axios = require('axios');


function authSaveData(accessToken, data, netlifySiteId, method, endpoint, updateNetlifyDomain, isChangesPublished, domainEnding) {
    
    const netlifyId = netlifySiteId ? {netlify_site_id: netlifySiteId} : {}
    const changesPublished = isChangesPublished ? {changes_published: isChangesPublished} : {}

    

    return dispatch => {

        let cleanedData = data && cloneDeep(data) || {}

        if(data && cleanedData.page){ 
            cleanedData.page.forEach((page, pageIndex) => {
                if(page.image && page.image.includes('http')) {
                    // delete cleanedData. 
                    cleanedData.page[pageIndex].image = undefined
                }

                page.section.forEach((section, sectionIndex) => {
                    if(section.image && section.image.includes('http')) {
                        // delete cleanedData. 
                        cleanedData.page[pageIndex].section[sectionIndex].image = undefined
                    }
                })
            }) 
        }

        const mergedData = {
            ...cleanedData,
            ...changesPublished,
            ...netlifyId
        }

        console.log('******** RUNNING SAVE ACTION, isChangesPublished ?', isChangesPublished)
        console.log('******** RUNNING SAVE ACTION, mergedData', mergedData)

        dispatch(dataSaveRequest());
        
        axios({
            method: method || 'put',
            url: `${process.env.GATSBY_API_ENDPOINT}/api/auth/${endpoint || 'website'}/`,
            data: mergedData,
            headers: {
                Authorization: `Bearer ${accessToken}`            
            }
        })
        .then((res) => {
            if(res.error) {
                console.log('authSaveData: throwing error: ', res.error)
                throw(res.error);
            }
            console.log('authSaveData: save successfull: ', res.data)
            dispatch(dataSaveSuccess({...res.data, ...changesPublished}));
            return res.data;
        })
        .then((res) => {
            if(updateNetlifyDomain && data.domain_name) {

                return axios.post('/.netlify/functions/netlify', {
                    method: 'patch',
                    endpoint: null,
                    payload: {
                        custom_domain: data.domain_name,
                        name: `${data.domain_name}.worksiter.${domainEnding}`,
                        custom_domain: `${data.domain_name}.worksiter.${domainEnding}`,
                        domain_aliases: [`wwww.${data.domain_name}.worksiter.${domainEnding}`],
                        url: `https://${data.domain_name}.worksiter.${domainEnding}`,
                    }, 
                    netlifySiteId: netlifySiteId
                })
            }
            else return res.data
        })
        .catch((err) => {
            console.log('authSaveData: catching error:  ', err.response && err.response.data.detail || err)
            dispatch(dataSaveFailure(err.response && err.response.data.detail || err));
        });
    }
}

export default authSaveData