import {emailPasswordResetRequest, emailPasswordResetSuccess, emailPasswordResetFailure} from '../actions/auth';
const axios = require('axios');

function resetPasswordEmail(email) {

    return dispatch => {

        dispatch(emailPasswordResetRequest());
        console.log('dispatching emailPasswordResetRequest')
        axios({
            method: 'post',
            url: `${process.env.GATSBY_API_ENDPOINT}/api/password_reset/`,
            data: {
                email: email
            },
        })
        .then((response) => {
            if(response.error) {
                console.log('emailPasswordResetFailure: throwing error: ', response.error.data.email)
                throw(response.error);
            }

            dispatch(emailPasswordResetSuccess());
            return true;
        })
        .catch((err) => {
            dispatch(
                emailPasswordResetFailure(
                    err.response && err.response.data && err.response.data.email && err.response.data.email[0] 
                    || err.response.status)
            )
        })
    }

}

export default resetPasswordEmail;